import React from "react";

import { NavBox, NavTitle, Day, StyledLink, TopicBox, ExternalLink } from "./rightNavComponents";

const WeekTwoNav = props => (
    <NavBox>
        <NavTitle />
        <Day>
            <StyledLink location="/week2/day1" name="Day 1" />
            <TopicBox>
                <ExternalLink type="lecture" name="p5.js" link="https://docs.google.com/presentation/d/1F4P_QOt1UavXAolu8f8Yk2gahu9Kpmm9rXPphI5jPMQ/edit?usp=sharing" />
                <ExternalLink type="lecture" name="Draw Loop + Mouse Events" link="https://docs.google.com/presentation/d/1nEyD_IT-TW3xu_k7RkmYV9Ljc1kNl3j3dlyQG-vwrYI/edit?usp=sharing" />
            </TopicBox>
        </Day>
        <Day>
            <StyledLink location="/week2/day2" name="Day 2" />
            <TopicBox>
                <ExternalLink type="lecture" name="Breaking Down Large Tasks" link="https://docs.google.com/presentation/d/1nYXQdG2ancfRlqSzNt6kf4e5tkM0Fdnb1rYAsDtrotg/edit?usp=sharing" />
                <ExternalLink type="lecture" name="States" link="https://docs.google.com/presentation/d/1lnlCaRU7fmjt6V_hj93sa4bHoEeqp0D5HWC-wz7avVI/edit?usp=sharing" />
            </TopicBox>
        </Day>
        <Day>
            <StyledLink location="/week2/day3" name="Day 3" />
            <TopicBox>
                <ExternalLink type="lecture" name="Github" link="https://docs.google.com/presentation/d/12l1MnSVsSnTl75jwuT5T6jiEFlF6oLggniDOoxkdYtg/edit?usp=sharing" />
                <ExternalLink type="lecture" name="MVP" link="https://docs.google.com/presentation/d/1sEp82KECpCJfPNy405rReKkpTnuyesx_EKLM8y4Nz54/edit?usp=sharing" />
            </TopicBox>
        </Day>
        <Day>
            <StyledLink location="/week2/day4" name="Day 4" />
            <TopicBox>
                {/* <ExternalLink type="" name="" link="" /> */}
            </TopicBox>
        </Day>
        <Day>
            <StyledLink location="/week2/day5" name="Day 5" />
            <TopicBox>
                <ExternalLink type="project" name="Design Your p5 Game!" link="https://drive.google.com/open?id=1qisM_TU4jaaVNblGCgRMszJjexu_U1jfPOQYBPaZoTo" />
                <ExternalLink type="project" name="Build Your p5 Game!" link="https://drive.google.com/open?id=13NFbcKdAeAc0WEk0d95aC1Du7M4ushi0KFz3GX8uLvA" />
            </TopicBox>
        </Day>
    </NavBox>
)

export default WeekTwoNav;