import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import Layout from "../components/layout.js";
import WeekTwoNav from "../components/rightnavs/weektwoRnav.js";
import WeekOverviewTitle from "../components/schedule_components/weektitle.js";
import { LinkContainer, LinkBox, ExternalLink } from "../components/schedule_components/weeklinks.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const DayScheduleWrapper = makeShortcode("DayScheduleWrapper");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <Layout rightnav={<WeekTwoNav mdxType="WeekTwoNav" />} mdxType="Layout">
    <DayScheduleWrapper mdxType="DayScheduleWrapper">
        <WeekOverviewTitle weeknum={`Week 02`} titleText="The fundamentals of JavaScript" tabTitle={`Week 02`} mdxType="WeekOverviewTitle" />
        <LinkContainer mdxType="LinkContainer">
            <LinkBox mdxType="LinkBox">
                <h1>Projects</h1>
                <ExternalLink type="project" name="p5.js" link="https://docs.google.com/document/d/17f5PErLFoT4nHnqM3aYNQZ9KDszqak8giWIPxXPcFPA/edit?usp=sharing" mdxType="ExternalLink" />
                <ExternalLink type="project" name="Draw Loop + Mouse Events" link="https://docs.google.com/document/d/1OTlAYtQmU9j51qxnwzLJpDgazVTQ5gZHKG4L0jUGB_o/edit?usp=sharing" mdxType="ExternalLink" />
                <ExternalLink type="project" name="Animation" link="https://docs.google.com/document/d/15wm34pui7uXl2Js2ogyf_yJ5jNN-otOD0tA_9YjbuD8/edit?usp=sharing" mdxType="ExternalLink" />
                <ExternalLink type="project" name="Breaking Down Large Tasks" link="https://docs.google.com/document/d/1x7_HQy_vtPBXIfDKF-42hdqba5adnswlIo6UT0-K46Q/edit?usp=sharing" mdxType="ExternalLink" />
                <ExternalLink type="project" name="States" link="https://docs.google.com/document/d/1mIrUToc1f4t873OW3Dn1s_KHmWkeGhf8VHldNqI7nA0/edit?usp=sharing" mdxType="ExternalLink" />
                <ExternalLink type="project" name="Objects" link="" mdxType="ExternalLink" />
                <ExternalLink type="project" name="Design Your p5 Game" link="https://drive.google.com/open?id=1qisM_TU4jaaVNblGCgRMszJjexu_U1jfPOQYBPaZoTo" mdxType="ExternalLink" />
                <ExternalLink type="project" name="Build Your p5 Game" link="https://drive.google.com/open?id=13NFbcKdAeAc0WEk0d95aC1Du7M4ushi0KFz3GX8uLvA" mdxType="ExternalLink" />
            </LinkBox>
            <LinkBox mdxType="LinkBox">
                <h1>Lectures / Codealongs</h1>
                <ExternalLink type="lecture" name="Lecture: p5.js" link="https://docs.google.com/presentation/d/1F4P_QOt1UavXAolu8f8Yk2gahu9Kpmm9rXPphI5jPMQ/edit?usp=sharing" mdxType="ExternalLink" />
                <ExternalLink type="lecture" name="Codealong: p5 Setup" link="https://docs.google.com/document/d/1ORGPgrNzcSBWIpvP9DkCFfsIEAMUKNW_uz5CcIeL5Kc/edit?usp=sharing" mdxType="ExternalLink" />
                <ExternalLink type="lecture" name="Codealong: Hello p5!" link="https://docs.google.com/document/d/1SK_JHjn3n2Kb3vGy_8JdUhKB6W_2p6pZEg5Urgh0-wM/edit?usp=sharing" mdxType="ExternalLink" />
                <ExternalLink type="lecture" name="Lecture: Draw Loop + Mouse Events" link="https://docs.google.com/presentation/d/1nEyD_IT-TW3xu_k7RkmYV9Ljc1kNl3j3dlyQG-vwrYI/edit?usp=sharing" mdxType="ExternalLink" />
                <ExternalLink type="lecture" name="Codealong: Draw Loop + Mouse Events" link="https://docs.google.com/document/d/13eZjZddAQvsU2XpnKbFbU-Sd4dqjkSTiD_wYRxsy8Rk/edit?usp=sharing" mdxType="ExternalLink" />
                <ExternalLink type="lecture" name="Codealong: Animation" link="https://docs.google.com/document/d/1dw9eCamyWwxf1rJO-ERp9ZqAnynRo04gVFRhcyKRU0w/edit?usp=sharing" mdxType="ExternalLink" />
                <ExternalLink type="lecture" name="Lecture: Breaking Down Large Tasks" link="https://docs.google.com/presentation/d/1nYXQdG2ancfRlqSzNt6kf4e5tkM0Fdnb1rYAsDtrotg/edit?usp=sharing" mdxType="ExternalLink" />
                <ExternalLink type="lecture" name="Codealong: Breaking Down Large Tasks" link="https://docs.google.com/document/d/1YkYGjr3yEgiC-io0peWlXy8A1aJXY4OlpUZHw0aB9aI/edit?usp=sharing" mdxType="ExternalLink" />
                <ExternalLink type="lecture" name="Lecture: States" link="https://docs.google.com/presentation/d/1lnlCaRU7fmjt6V_hj93sa4bHoEeqp0D5HWC-wz7avVI/edit?usp=sharing" mdxType="ExternalLink" />
                <ExternalLink type="lecture" name="Codealong: States I" link="https://docs.google.com/document/d/1oADLag1ccSO2hrEg2sfTQbeycYZA54_3ge5nicl73KU/edit?usp=sharing" mdxType="ExternalLink" />
                <ExternalLink type="lecture" name="Codealong: States II" link="https://docs.google.com/document/d/1PavzK_vaCj74wQeYYpSd7r3NHBwyhAMnh5uewnZoW_U/edit?usp=sharing" mdxType="ExternalLink" />
                <ExternalLink type="lecture" name="Codealong: Bouncing Ball I" link="https://docs.google.com/document/d/1eSFNCymwSA4G0ojgQEWSh8TavRVtCsfqjnYXsKdQdk8/edit?usp=sharing" mdxType="ExternalLink" />
                <ExternalLink type="lecture" name="Codealong: Bouncing Ball II" link="https://docs.google.com/document/d/1sbdE7gvUu-agP_RYOrrW8JB-2LWP4_Ex05kF5pgV1xM/edit?usp=sharing" mdxType="ExternalLink" />
                <ExternalLink type="lecture" name="Codealong: Images and Sounds" link="https://docs.google.com/document/d/1PCEMtkA90fJ6E5mPJqzPz7ra1m-vG1MGp71Yh6IdKAA/edit?usp=sharing" mdxType="ExternalLink" />
            </LinkBox>
            <LinkBox mdxType="LinkBox">
                <h1>Core Skills</h1>
                <ExternalLink type="coreskill" name="Github" link="https://docs.google.com/presentation/d/12l1MnSVsSnTl75jwuT5T6jiEFlF6oLggniDOoxkdYtg/edit?usp=sharing" mdxType="ExternalLink" />
                <ExternalLink type="coreskill" name="MVP" link="https://docs.google.com/presentation/d/1sEp82KECpCJfPNy405rReKkpTnuyesx_EKLM8y4Nz54/edit?usp=sharing" mdxType="ExternalLink" />
                {
              /* <ExternalLink type="" name="" link="" /> */
            }
            </LinkBox>
        </LinkContainer>
    </DayScheduleWrapper>
    </Layout>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      